<template lang="html">
  <b-card>
    <div class="p-3">
      <div class="user-deliveries">
        <div class="pt-0 pt-md-3">
          <div>
            <span class="card-title">Historial de entregas</span>
          </div>
          <b-row class="filter-container mt-3">
            <!-- Search input -->
            <b-col class="z-search-filter-col pl-md-3">
              <z-search-input v-model="searchText" placeholder="Busca por ID" />
            </b-col>
            <!-- Delivery filter -->
            <b-col lg="2" class="mt-3 mt-lg-0">
              <z-dropdown
                id="dropdown-status"
                v-model="filterDeliveryType.value"
                :options="serviceTypeOptions"
                name="Entrega"
              />
            </b-col>
            <!-- Date filter -->
            <b-col lg="3" xl="2" class="mt-3 mt-md-3 mt-lg-0">
              <z-dropdown
                id="dropdown-date"
                v-model="filterDate.value"
                :options="dateOptions"
                name="Fecha"
              />
            </b-col>
            <!-- Status filter -->
            <b-col lg="2" class="mt-3 mt-md-3 mt-lg-0">
              <z-dropdown
                id="dropdown-status"
                v-model="filterStatus.value"
                :options="statusOptions"
                name="Estatus"
              />
            </b-col>
            <b-col
              v-if="showDatePicker"
              sm="3"
              lg
              class="mt-3 mt-md-0 d-flex px-2"
            >
              <z-date-picker
                v-model="date.from"
                calendar-class="date-picker"
                placeholder="Desde"
              />
              <div class="d-flex align-items-center mx-1">-</div>
              <z-date-picker
                v-model="date.until"
                calendar-class="date-picker"
                placeholder="Hasta"
              />
            </b-col>
          </b-row>
          <div class="mt-4">
            <z-table
              responsive="lg"
              hover
              :items="deliveries"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :busy="isLoading"
              @row-clicked="handleRowClick"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <loading-spinner />
                </div>
              </template>
              <template v-slot:empty>
                <p class="text-center py-4">
                  No hay entregas registradas durante estas fechas
                </p>
              </template>
              <template v-slot:head(createdAt)>
                <z-table-head-sort :order.sync="sortOrder">
                  Fecha
                </z-table-head-sort>
              </template>
              <template v-slot:head(type)>
                <div>
                  <div class="d-lg-none">
                    Entrega
                  </div>
                  <z-table-header-dropdown
                    id="dropdown-service-type"
                    v-model="filterDeliveryType.value"
                    class="d-none d-lg-block"
                    :options="serviceTypeOptions"
                    name="Entrega"
                  />
                </div>
              </template>
              <template v-slot:head(vehicleType)>
                <div>
                  <div class="d-lg-none">
                    Vehículo
                  </div>
                  <b-dropdown
                    id="dropdown-vehicle-type"
                    class="z-dropdown dropdown-table-header d-none d-lg-block"
                    variant="light"
                    block
                    no-caret
                  >
                    <template v-slot:button-content>
                      <font-awesome-icon icon="filter" />
                    </template>
                    <div>
                      <b-dropdown-header id="dropdown-vehicle-type-header">
                        Mostrar
                      </b-dropdown-header>
                      <b-dropdown-item-button
                        v-for="option in vehicleTypeOptions"
                        :key="`status-${option.value}`"
                        :active="filterVehicleType.value === option.value"
                        @click="filterVehicleType = option"
                      >
                        {{ option.text }}
                      </b-dropdown-item-button>
                    </div>
                  </b-dropdown>
                </div>
              </template>
              <!-- Head cell for service status -->
              <template v-slot:head(status)>
                <div style="width: 80px;">
                  <div class="d-lg-none">
                    Estado
                  </div>
                  <z-table-header-dropdown
                    id="dropdown-service-status"
                    v-model="filterStatus.value"
                    class="d-none d-lg-block"
                    :options="statusOptions"
                    name="Estatus"
                  />
                </div>
              </template>
              <!-- Data cell for delivery type -->
              <template v-slot:cell(deliveryType)="row">
                <service-type-badge :type="row.item.deliveryType" />
              </template>
              <!-- Data cell for vehicle type -->
              <template v-slot:cell(vehicleType)="row">
                <z-vehicle
                  v-if="row.item.vehicleType != null"
                  :type="row.item.vehicleType"
                />
                <z-vehicle v-else :type="VehicleType.NUM_TRUCK" />
              </template>
              <!-- Data cell for service status -->
              <template v-slot:cell(status)="row">
                <status-destination :status="row.item.status" />
              </template>
            </z-table>
            <z-table-pagination
              :total-rows="totalRows"
              :per-page.sync="perPage"
              :current-page.sync="currentPage"
            />
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { initialize } from "@zubut/common/src/utils/array";
import { formatMoney } from "@zubut/common/src/utils/money";
import { makeShortUUID } from "@zubut/common/src/utils/strings";
import DateFilters from "@zubut/common/src/constants/filters/date";
import StatusDestination from "@zubut/common/src/components/StatusDestination";
import ZDatePicker from "@zubut/common/src/components/ZDatePicker";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZVehicle from "@zubut/common/src/components/ZVehicle";
import ZTableHeadSort from "@zubut/common/src/components/ZTableHeadSort";
import {
  dateRange,
  formatISO,
  formatToISO
} from "@zubut/common/src/utils/time";
import ServiceAccess from "@/constants/access/services";
import DeliveryAccess from "@/constants/access/deliveries";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ZTableHeaderDropdown from "@zubut/common/src/components/ZTableHeaderDropdown";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import ServiceType from "@zubut/common/src/constants/services/type";
import ServiceTypeBadge from "@zubut/common/src/components/ServiceTypeBadge";
import VehicleType from "@zubut/common/src/constants/vehicles/type";
import DeliveryStatusConstants from "@zubut/common/src/constants/destinations/status";
import pagination from "@/mixins/pagination";
import _debounce from "lodash/debounce";

export default {
  name: "Deliveries",

  components: {
    ServiceTypeBadge,
    StatusDestination,
    ZDatePicker,
    ZDropdown,
    ZSearchInput,
    ZTable,
    ZTableHeaderDropdown,
    ZTableHeadSort,
    ZTablePagination,
    ZVehicle
  },

  mixins: [pagination],

  data() {
    return {
      active: false,
      isLoading: true,
      deliveries: [],
      dateOptions: DateFilters.options,
      statusOptions: [{ text: "Todos", value: null }].concat(
        DeliveryStatusConstants.optionsHistory
      ),
      serviceTypeOptions: [{ text: "Todas", value: null }].concat(
        ServiceType.options
      ),
      vehicleTypeOptions: [{ text: "Todos", value: null }].concat(
        VehicleType.options
      ),
      sortBy: "createdAt",
      sortOrder: "desc",
      fields: [
        {
          key: "deliveryType",
          label: "Servicio"
        },
        {
          key: "id",
          label: "ID Entrega",
          formatter: value => makeShortUUID(value)
        },
        {
          key: "createdAt",
          label: "Fecha",
          formatter: value => formatISO(value, "d MMM u H:mm")
        },
        {
          key: "deliverTo",
          label: "Cliente"
        },
        {
          key: "vehicleType"
        },
        {
          key: "subtotal",
          label: "Subtotal"
        },
        {
          key: "tax",
          label: "IVA"
        },
        {
          key: "total",
          label: "Total"
        },
        {
          key: "status",
          label: "Estado"
        }
      ],
      placeholderItem: {
        deliveryType: 0,
        id: "",
        createdAt: "",
        deliverTo: "",
        vehicleType: 0,
        subtotal: 0,
        tax: 0,
        total: 0,
        status: 0
      },
      searchText: ""
    };
  },

  computed: {
    filters: {
      get() {
        return this.$store.getters["deliveries/filter"];
      },
      set(value) {
        this.$store.commit("deliveries/setFilter", value);
      }
    },

    filterDate: {
      get() {
        return this.$store.getters["deliveries/filter"].date;
      },
      set(value) {
        this.$store.commit("deliveries/setFilterDate", value);
      }
    },

    filterStatus: {
      get() {
        return this.$store.getters["deliveries/filter"].status;
      },
      set(value) {
        this.$store.commit("deliveries/setFilterStatus", value);
      }
    },

    filterDeliveryType: {
      get() {
        return this.$store.getters["deliveries/filter"].serviceType;
      },
      set(value) {
        this.$store.commit("deliveries/setFilterServiceType", value);
      }
    },

    filterVehicleType: {
      get() {
        return this.$store.getters["deliveries/filter"].vehicleType;
      },
      set(value) {
        this.$store.commit("deliveries/setFilterVehicleType", value);
      }
    },

    date: {
      get() {
        return this.$store.getters["deliveries/date"];
      },
      set(value) {
        return this.$store.commit("deliveries/setDate", value);
      }
    },

    showDatePicker() {
      return this.filters.date.value === DateFilters.NUM_CUSTOM;
    },

    disableDatesFrom() {
      let date;
      if (this.date.until != null) {
        date = new Date(this.date.until);
      } else {
        date = new Date();
      }
      return { from: date };
    },

    disableDatesUntil() {
      let date;
      if (this.date.from != null) {
        date = new Date(this.date.from);
      } else {
        date = new Date();
      }
      return { to: date };
    }
  },

  watch: {
    currentPage: "getDeliveries",
    perPage: "getDeliveries",
    date: {
      deep: true,
      handler() {
        if (this.filterDate.value === DateFilters.NUM_CUSTOM) {
          this.getDeliveriesAndResetPagination();
        }
      }
    },
    filters: {
      deep: true,
      handler() {
        this.getDeliveriesAndResetPagination();
      }
    },
    sortOrder: "getDeliveries",
    searchText() {
      this.search();
    }
  },

  created() {
    this.search = _debounce(() => {
      this.getDeliveriesAndResetPagination();
    }, 600);
  },

  beforeMount() {
    this.getDeliveries();
  },

  methods: {
    buildWhereFilter() {
      const where = {
        archived: { neq: true },
        id: { ilike: `%${this.searchText}%` }
      };

      /* Set service type filter */
      if (this.filterDeliveryType.value !== null) {
        let deliveryTypes;
        if (ServiceType.isMultiPoint(this.filterDeliveryType.value)) {
          const { NUM_SIMPLE, NUM_MULTI_POINT, NUM_DYNAMIC } = ServiceType;
          deliveryTypes = [NUM_SIMPLE, NUM_MULTI_POINT, NUM_DYNAMIC];
        } else if (ServiceType.isParcel(this.filterDeliveryType.value)) {
          deliveryTypes = ServiceType.parcelTypes;
        } else {
          deliveryTypes = [this.filterDeliveryType.value];
        }
        where.deliveryType = { inq: deliveryTypes };
      }

      /* Set status filter */
      if (this.filterStatus.value !== null) {
        where.status = this.filterStatus.value;
      }

      /* Set vehicle type filter */
      if (this.filterVehicleType.value !== null) {
        where.vehicleType = this.filterVehicleType.value;
      }

      if (this.filterDate.value !== DateFilters.NUM_CUSTOM) {
        const newDate = dateRange(this.filterDate.value);
        if (newDate.from != null && newDate.until != null) {
          newDate.from = formatToISO(newDate.from);
          newDate.until = formatToISO(newDate.until);
        }
        this.date = newDate;
      }

      if (this.date.from && this.date.until) {
        where.and = [
          {
            createdAt: {
              gte: this.date.from
            }
          },
          {
            createdAt: {
              lte: this.date.until
            }
          }
        ];
      }

      return where;
    },

    getDeliveries() {
      this.isLoading = true;
      const where = this.buildWhereFilter();
      this.$store
        .dispatch("deliveries/getDeliveries", {
          filter: { ...this.filter, order: `${this.sortBy} ${this.sortOrder}` },
          where
        })
        .then(({ data: deliveries, meta }) => {
          if (meta.skip === 0) {
            this.totalRows = meta.count;
            this.deliveries = initialize({
              value: this.placeholderItem,
              size: this.totalRows
            });
          }
          deliveries = deliveries.map(delivery => {
            delivery.tax = formatMoney(delivery.tax);
            delivery.total = formatMoney(delivery.total);
            delivery.subtotal = formatMoney(delivery.subtotal);
            return delivery;
          });
          this.deliveries.splice(meta.skip, deliveries.length, ...deliveries);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // Note: Open delivery details, but right now the API return 404 in this call
    // Ask to the API team to fix this
    handleRowClick(item) {
      const canReadDeliveryDetail = this.$can(
        DeliveryAccess.READ_DELIVERY_DETAIL,
        DeliveryAccess.moduleName
      );
      const canReadServiceDetail = this.$can(
        ServiceAccess.READ_SERVICE_DETAIL,
        ServiceAccess.moduleName
      );
      if (ServiceType.isParcel(item.deliveryType)) {
        if (!canReadDeliveryDetail) return;
        this.$router.push({
          name: "deliveryDetail",
          params: { id: item.id },
          query: { type: item.deliveryType }
        });
      } else {
        if (!canReadServiceDetail) return;
        this.$router.push({
          name: "serviceDetail",
          params: { id: item.zubutId }
        });
      }
    },

    paginationReset() {
      this.totalRows = 0;
      this.deliveries = [];
    },

    getDeliveriesAndResetPagination() {
      this.paginationReset();
      this.getDeliveries();
    }
  }
};
</script>

<style lang="scss" scoped>
.z-search-filter {
  cursor: text;
  min-width: 250px;
  background-color: $white;
  border: 1px solid $gainsboro;
  border-radius: 0.25rem;

  .icon {
    color: $nobel;
  }

  input {
    cursor: text;
    color: $nero;
    min-height: 32px;
    font-size: 12px;
    border-color: transparent;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

::v-deep .date-picker-input {
  width: 120px;
}

@media (min-width: 992px) {
  .z-search-filter-col {
    max-width: 280px;
  }
}
</style>
